<template>
  <div>
    <!--begin::customer-->
    <b-card no-body class="mb-5">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">


              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('sales_via_interval_report.from_date') }}</label>
                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('sales_via_interval_report.to_date') }}</label>
                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
              </div>


              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <!-- End Filter -->
    <div class="card card-custom">
      <div class="card-body">

        <!-- Filter -->
        <div>

          <div class="m-form m-form--fit m--margin-bottom-20">

            <b-tabs content-class="mt-3">

              <b-tab :title="$t('summery')" :active="tab == 'summery' || tab == null">
                <div class="mt-3">
                  <div class="row mb-10">
                    <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
                      <h4 class="text-center">{{ $t('sales_via_interval_report.sales_via_interval_report') }}</h4>
                      <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
                    </div>
                  </div>
                  <div class="row mb-10">
                    <div class="col-12" v-if="data_report_details">
                      <div class="table-responsive">
                        <div class="row justify-content-end p-4">
                          <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                            <download-excel
                                class="dropdown-item"
                                :fetch="salesInvoiceDetailsExport"
                                :fields="json_fields_details"
                                :name="'sales_via_interval.xls'">
                              <i class="la la-file-excel"></i>{{ $t('excel') }}
                            </download-excel>
                            <button class="dropdown-item" @click="printData('summaryTableDetails', 'print')">
                              <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                            </button>
                            <button class="dropdown-item" @click="printData('summaryTableDetails', 'pdf')">
                              <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                            </button>
                          </b-dropdown>
                        </div>
                        <table class="table table-bordered" id="summaryTableDetails">
                          <thead>
                          <tr>
                            <th>{{ $t('sales_via_interval_report.invoice_code') }}</th>
                            <th>{{ $t('sales_via_interval_report.invoice_date') }}</th>
                            <th>{{ $t('sales_via_interval_report.invoice_total') }} ({{ currency_name }})</th>
                            <th>{{ $t('sales_via_interval_report.customer_name') }}</th>
                            <th>{{ $t('sales_via_interval_report.customer_tax_register') }}</th>
                          </tr>
                          </thead>
                          <tbody>
                          <template v-for="(row, index) in data_report_details">
                            <tr :key="'b'+index">
                              <td>{{ row.invoice_code }}</td>
                              <td>{{ row.invoice_date }}</td>
                              <td>{{ row.invoice_total }}</td>
                              <td>{{ row.customer_name }}</td>
                              <td>{{ row.customer_tax_register }}</td>
                            </tr>
                            <tr v-for="(row2, index2) in row.items_list" :key="'a'+getRandom()+index2">
                              <td>{{ row.invoice_code }}</td>
                              <td colspan="2">{{ row2.item_name }}</td>
                              <td colspan="2">{{ row2.qty }}</td>
                            </tr>
                          </template>

                          </tbody>

                        </table>
                      </div>

                    </div>
                  </div>
                </div>
              </b-tab>
              <!-- <b-tab> -->
              <!-- </b-tab> -->
            </b-tabs>
            <div class="col-12 text-center" v-if="page">
              <button class="btn btn-warning" @click="loadMore">
                <p class="mb-0">
                  <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                  {{ $t('load_more') }}
                </p>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="selector-export" id="selectorExport">
      <div id="selectorHeader">
        <main-header :inner-data="company_data" :data-header="template"></main-header>
      </div>
    </div>
    <!--end::customer-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import MainHeader from "@/view/content/printing-heads/MainHeader.vue";

export default {
  name: "index-sales-via-interval-report",
  components: {MainHeader},
  data() {
    return {
      mainRoute: '/reports/sales_via_interval',
      mainRouteDependency: 'base/dependency',

      currency_name: null,

      data_report_details: [],


      filters: {
        from_date: null,
        to_date: null,
      },


      data: [],
      isDisabled: true,
      tab: this.$route.query.tab ? this.$route.query.tab : null,
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,

      templateMainRoute: 'settings/print_setting/type',
      template_for: 'SalesInvoice',
      template: null,
      content_temp: [],

      is_multi_language_label: false,
      is_break_before_footer: false,
      is_payment_details: false,
      is_banking_details: false,
      footer_font_color: '#000',
      company_data: {
        company: []
      },
    }
  },
  computed: {

    json_fields: function () {
      let fields = {};

      fields[this.$t('sales_via_interval_report.invoice_code')] = 'invoice_code';
      fields[this.$t('sales_via_interval_report.invoice_date')] = 'invoice_date';
      fields[this.$t('sales_via_interval_report.invoice_total') + ` (${this.currency_name})`] = 'invoice_total';
      fields[this.$t('sales_via_interval_report.customer_name')] = 'customer_name';
      fields[this.$t('sales_via_interval_report.customer_tax_register')] = 'customer_tax_register';

      return fields;
    },

    json_fields_details: function () {
      let fields = {};

      fields[this.$t('sales_via_interval_report.invoice_code')] = 'invoice_code';
      fields[this.$t('sales_via_interval_report.invoice_date')] = 'invoice_date';
      fields[this.$t('sales_via_interval_report.invoice_total') + ` (${this.currency_name})`] = 'invoice_total';
      fields[this.$t('sales_via_interval_report.customer_name')] = 'customer_name';
      fields[this.$t('sales_via_interval_report.customer_tax_register')] = 'customer_tax_register';
      return fields;
    },


  },
  created() {
    this.getCompanyData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_reports"), route: '/reports/sales-reports-links'}, {title: this.$t("MENU.sales_reports")}]);
    this.filters.filter_by = this.$route.query.filter ? this.$route.query.filter : null;

    this.getAuthUser();
    this.printSetting();
  },
  methods: {
    async printSetting() {
      await ApiService.get(`${this.templateMainRoute}/${this.template_for}`).then((response) => {
        this.template = response.data.data;
        this.content_temp = response.data.data.content_temp;
        this.is_multi_language_label = response.data.data.is_multi_language_label ? response.data.data.is_multi_language_label : false;
        this.is_break_before_footer = response.data.data.is_break_before_footer ? response.data.data.is_break_before_footer : false;
        this.is_payment_details = response.data.data.is_payment_details ? response.data.data.is_payment_details : false;
        this.is_banking_details = response.data.data.is_banking_details ? response.data.data.is_banking_details : false;
        this.footer_font_color = response.data.data.footer_font_color ? response.data.data.footer_font_color : '#000';

      });
    },

    salesInvoiceDetailsExport() {
      this.setReportLog('excel', 'sales via interval report');

      let _data = [];
      this.data_report_details.forEach((row) => {
        _data.push({
          invoice_code: row.invoice_code,
          invoice_date: row.invoice_date,
          invoice_total: row.invoice_total,
          customer_name: row.customer_name,
          customer_tax_register: row.customer_tax_register,
        });
        row.items_list.forEach((sub_row) => {
          _data.push({
            invoice_code: row.invoice_code,
            customer_name: sub_row.item_name,
            customer_tax_register: sub_row.qty,
          });
        });

      });


      return _data;
    },

    getRandom() {
      return Math.floor(Math.random() * 10000);
    },


    doFilter() {
      this.page = 1;
      this.getReportInvoiceDetails();

    },
    resetFilter() {
      this.filters.from_date = null;
      this.filters.to_date = null;
    },


    getAuthUser() {
      ApiService.get(`/get_auth`).then((response) => {
        this.currency_name = response.data.data.currency_name;
      });
    },

    getCompanyData() {
      ApiService.get(this.mainRouteDependency +`/company_data`).then((response) => {
        this.company_data.company = response.data.data;
      });
    },


    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getReportInvoiceDetails();
    },
    /*****
     * details
     */


    getReportInvoiceDetails() {
      ApiService.get(`${this.mainRoute}`, {params: {...this.filters, page: (this.page ? this.page : 1)}}).then((response) => {
        this.data_report_details = response.data.data.data;
      });
    },

    printData(tableId, type) {
      this.setReportLog('pdf', 'sales via interval report');

      exportPDFInnerAction(tableId, type, this.$t('MENU.sales_via_interval_reports'), this.$t('MENU.sales_via_interval_reports'));

    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}
</style>

